import React from 'react';
import PropTypes from 'prop-types';
import getAuthorByName from '../../repositories/getAuthorByName';

const Bio = ({ name, postCount }) => {
  const author = getAuthorByName(name);

  return (
    <section className="author-profile inner">
      <figure className="author-image">
        <div
          className="img"
          style={{
            backgroundImage: `url(${author.frontmatter.picture})`,
          }}
        >
          <span className="hidden">{name}’s Picture</span>
        </div>
      </figure>
      <h1 className="author-title">{name}</h1>
      <h2 className="author-bio">{author.frontmatter.description}</h2>
      <div className="author-meta">
        {author.frontmatter.location && (
          <span className="author-location icon-location">
            {author.frontmatter.location}
          </span>
        )}
        {author.frontmatter.website && (
          <span className="author-link icon-link">
            <a
              href={author.frontmatter.website}
              target="_blank"
              rel="noopener noreferrer"
            >
              {author.frontmatter.website}
            </a>
          </span>
        )}
        <span className="author-stats">
          <i className="icon-stats"></i> {postCount === 0 && 'No posts'}
          {postCount < 2 && `${postCount} post`}
          {postCount > 1 && `${postCount} posts`}
        </span>
      </div>
    </section>
  );
};

Bio.propTypes = {
  name: PropTypes.string.isRequired,
  postCount: PropTypes.number.isRequired,
};

export default Bio;
