import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import Layout from '../components/Layout';
import { ApplicationContext } from '../components/Contexts/ApplicationContext';
import BlogRoll from '../components/Blog/BlogRoll';
import Bio from '../components/Author/Bio';

const AuthorRoute = (props) => {
  return (
    <Layout>
      <AuthorPage props={props} />
    </Layout>
  );
};

export default AuthorRoute;

const AuthorPage = ({ props }) => {
  const context = useContext(ApplicationContext);
  const posts = props.data.allMarkdownRemark.edges;
  const authorName = props.pageContext.title;
  const coverImage = props.pageContext.coverImage;
  const siteTitle = props.data.site.siteMetadata.title;
  const postCount = props.data.allMarkdownRemark.totalCount;

  return (
    <>
      <Helmet title={`${authorName} | ${siteTitle}`}>
        <body
          className={context.actions.getBodyClassNames('author-template')}
        />
      </Helmet>
      <header
        className={classNames('main-header', 'author-head', {
          'no-cover': !coverImage,
        })}
        style={{ backgroundImage: coverImage ? `url(${coverImage})` : null }}
      >
        <nav className="main-nav overlay clearfix">
          <a
            className="menu-button icon-menu"
            href="#menu"
            onClick={context.actions.toggleMenu}
          >
            <span className="word">Menu</span>
          </a>
        </nav>
      </header>

      <Bio name={authorName} postCount={postCount} />

      <main className="content" role="main">
        <BlogRoll posts={posts} count={postCount} />
      </main>
    </>
  );
};

export const authorPageQuery = graphql`
  query AuthorPage($title: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { author: { eq: $title } } }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt(pruneLength: 400)
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            isFeatured
            coverImage
            tags {
              tag
            }
            author
          }
        }
      }
    }
  }
`;
